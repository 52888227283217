var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "main-warp" }, [
    _c(
      "div",
      { staticClass: "main-header", staticStyle: { background: "#f5f5f5" } },
      [_c("app-hearder")],
      1
    ),
    _vm._v(" "),
    _c("div", { staticClass: "main-body" }, [
      _c(
        "div",
        {
          staticClass: "main-menu",
          staticStyle: { background: "rgb(48, 65, 86)" }
        },
        [_c("app-menu")],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "main-content" },
        [
          _c("app-back"),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "dashboard__container" },
            [_c("router-view")],
            1
          )
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }