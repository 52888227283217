var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "data-form" },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: {
            "label-width": _vm.labelWidth,
            "label-suffix": _vm.labelSuffix,
            model: _vm.model,
            inline: _vm.inline,
            disabled: _vm.disabled
          },
          nativeOn: {
            submit: function($event) {
              $event.preventDefault()
            }
          }
        },
        [_vm._t("default")],
        2
      ),
      _vm._v(" "),
      _vm.$slots.after
        ? _c(
            "div",
            { staticStyle: { "text-align": "right", overflow: "hidden" } },
            [_vm._t("after")],
            2
          )
        : !!_vm.$parent.dialog
        ? _c(
            "div",
            { staticStyle: { "text-align": "right", overflow: "hidden" } },
            [
              _c(
                "app-button",
                { attrs: { type: "text" }, on: { click: _vm.close } },
                [_vm._v("关闭")]
              ),
              _vm._v(" "),
              !_vm.disabled
                ? _c(
                    "app-button",
                    {
                      attrs: {
                        type: "primary",
                        size: "big",
                        loading: _vm.$parent.posting
                      },
                      on: { click: _vm.save }
                    },
                    [_vm._v("保存")]
                  )
                : _vm._e()
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }