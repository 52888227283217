
let DATA = {
    PREFIX: 'project-modle-',
    USER: 'user_data',
    TOKEN: 'token',
    USER_LEVEL: 'user_level'
};

let PROXY_URL = '/luke';

let NORMAL = {

};

export default {
    DATA,
    NORMAL,
    PROXY_URL
}