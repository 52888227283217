var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "dashboard__menu" }, [
    _c("div", { staticStyle: { height: "1px" } }),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "dashboard__menu-list" },
      [
        _c("app-page", { ref: "page" }, [
          _c(
            "div",
            { staticStyle: { width: "2rem" } },
            [
              _vm._l(_vm.menu, function(parentMenu) {
                return parentMenu.meta.level.indexOf(_vm.level) >= 0
                  ? [
                      !parentMenu.children
                        ? _c(
                            "router-link",
                            {
                              class: { true: "link-active" }[
                                !parentMenu.children &&
                                  _vm.currentUrl === "/" + parentMenu.path
                              ],
                              attrs: {
                                to: {
                                  path: "/dashboard/" + parentMenu.path,
                                  query: { menu: _vm.menu.name }
                                }
                              }
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "dashboard__menu-item" },
                                [_vm._v(_vm._s(parentMenu.name))]
                              )
                            ]
                          )
                        : _c(
                            "div",
                            {
                              staticClass:
                                "dashboard__menu-item dashboard__menu--parent",
                              class: { true: "link-active" }[
                                !parentMenu.children &&
                                  _vm.currentUrl === "/" + parentMenu.path
                              ],
                              on: {
                                click: function($event) {
                                  return _vm.openMenu(
                                    parentMenu,
                                    !parentMenu.open
                                  )
                                }
                              }
                            },
                            [
                              _c("span", [_vm._v(_vm._s(parentMenu.name))]),
                              _vm._v(" "),
                              _c("i", {
                                staticClass: "el-icon-menu-right",
                                class: [parentMenu.open ? "open" : "close"]
                              })
                            ]
                          ),
                      _vm._v(" "),
                      parentMenu.open && parentMenu.children
                        ? _c(
                            "div",
                            _vm._l(parentMenu.children, function(childMenu) {
                              return childMenu.meta.level.indexOf(_vm.level) >=
                                0
                                ? _c(
                                    "router-link",
                                    {
                                      key: childMenu.id,
                                      staticClass: "dashboard__menu-item",
                                      class: { true: "link-active" }[
                                        _vm.currentUrl.includes(
                                          "/" +
                                            parentMenu.path +
                                            "/" +
                                            childMenu.path
                                        )
                                      ],
                                      attrs: {
                                        to: {
                                          path:
                                            "/dashboard/" +
                                            parentMenu.path +
                                            "/" +
                                            childMenu.path,
                                          query: { menu: _vm.menu.name }
                                        }
                                      }
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticStyle: { "margin-left": "10px" }
                                        },
                                        [_vm._v(_vm._s(childMenu.name))]
                                      )
                                    ]
                                  )
                                : _vm._e()
                            }),
                            1
                          )
                        : _vm._e()
                    ]
                  : _vm._e()
              }),
              _vm._v(" "),
              _c("div", {
                staticClass: "menu__empty",
                staticStyle: { height: "30px" }
              })
            ],
            2
          )
        ])
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }