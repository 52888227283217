var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      ref: "dialog",
      attrs: {
        "close-on-click-modal": false,
        title: _vm.title,
        visible: _vm.show,
        width: _vm.width + "px",
        top: "0",
        "append-to-body": true
      },
      on: { closed: _vm.onClose }
    },
    [
      _c("app-page", { ref: "scroll", staticClass: "dialog__body" }, [
        _c("div", {
          staticClass: "dialog__body--inner",
          style: { width: _vm.width + "px" }
        })
      ]),
      _vm._v(" "),
      _c("div", {
        staticClass: "dialog__footer",
        staticStyle: { display: "none" }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }