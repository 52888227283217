var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("app-page", { staticClass: "index-img-holder" }, [
    _c("div", [
      _c("img", {
        attrs: {
          src: "https://appluke.oss-cn-hangzhou.aliyuncs.com/hp.jpg",
          alt: ""
        },
        on: { click: _vm.go }
      })
    ]),
    _vm._v(" "),
    _c(
      "div",
      {
        staticStyle: {
          "text-align": "center",
          background: "black",
          height: "50px",
          "line-height": "50px",
          "font-size": "20px",
          color: "white"
        },
        on: { click: _vm.go }
      },
      [_vm._v("进入管理界面")]
    ),
    _vm._v(" "),
    _c("div", { staticStyle: { "text-align": "center" } }, [
      _c(
        "a",
        { attrs: { href: "https://beian.miit.gov.cn/", target: "_blank" } },
        [_vm._v("\n          备案号：浙ICP备15044370号-2\n         ")]
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }