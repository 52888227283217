var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticStyle: {
        width: "100%",
        display: "flex",
        "flex-direction": "column",
        height: "100%"
      }
    },
    [
      _c(
        "el-tabs",
        {
          attrs: { type: "card" },
          model: {
            value: _vm.currentActive,
            callback: function($$v) {
              _vm.currentActive = $$v
            },
            expression: "currentActive"
          }
        },
        _vm._l(_vm.tabs, function(tab, index) {
          return _c("el-tab-pane", {
            key: index,
            attrs: { name: index + "", label: tab }
          })
        }),
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "tab-body", staticStyle: { position: "relative" } },
        _vm._l(_vm.tabs, function(tab, index) {
          return _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: parseInt(_vm.currentActive) === index,
                  expression: "parseInt(currentActive) === index"
                }
              ],
              key: index,
              staticStyle: { width: "100%" }
            },
            [_vm._t(index + 1)],
            2
          )
        }),
        0
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }