var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("el-autocomplete", {
    attrs: {
      placeholder: _vm.placeholder,
      size: "small",
      maxlength: _vm.maxlength,
      "fetch-suggestions": _vm.querySearch,
      clearable: _vm.clearable,
      "value-key": _vm.valueName,
      "select-when-unmatched": true,
      disabled: _vm.disabled
    },
    on: { select: _vm.change, blur: _vm.blur },
    model: {
      value: _vm.nativeValue,
      callback: function($$v) {
        _vm.nativeValue = $$v
      },
      expression: "nativeValue"
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }