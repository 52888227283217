var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "dashboard__header", class: { shadow: _vm.shadow } },
    [
      _c("div", { staticClass: "fl logo-wrap", on: { click: _vm.goIndex } }, [
        _vm._m(0)
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "fr",
          staticStyle: {
            position: "relative",
            "z-index": "4",
            top: "50%",
            margin: "-0.15rem 10px"
          }
        },
        [
          _c(
            "el-dropdown",
            { on: { command: _vm.handleClick } },
            [
              _c("span", { staticClass: "el-dropdown-link m-link" }, [
                _vm._v("\n            " + _vm._s(_vm.USER)),
                _c("i", { staticClass: "el-icon-arrow-down el-icon--right" })
              ]),
              _vm._v(" "),
              _c(
                "el-dropdown-menu",
                { attrs: { slot: "dropdown" }, slot: "dropdown" },
                [
                  _c(
                    "el-dropdown-item",
                    { attrs: { command: "changePassword" } },
                    [_vm._v("修改密码")]
                  ),
                  _vm._v(" "),
                  _c("el-dropdown-item", { attrs: { command: "logout" } }, [
                    _vm._v("退出登录")
                  ])
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _vm._t("default")
    ],
    2
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "logo", staticStyle: { display: "inline-block" } },
      [
        _c("img", {
          attrs: {
            src: require("../image/logo-s.png"),
            onerror: "this.src='../image/logo-s.png'"
          }
        })
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }