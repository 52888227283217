var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-button",
    {
      class: _vm.icon ? "icon-button" : "text-button",
      attrs: {
        icon: _vm.icon ? "el-icon-" + _vm.icon : "",
        disabled: _vm.disabled,
        loading: _vm.loading,
        type: _vm.type,
        size: _vm.size
      },
      on: { click: _vm.click }
    },
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }