var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { width: "100%", height: "100%" } },
    [
      _vm.center
        ? _c(
            "baidu-map",
            {
              staticClass: "bm-view",
              attrs: {
                "scroll-wheel-zoom": true,
                center: _vm.center,
                zoom: _vm.zoom,
                "map-click": false
              },
              on: {
                click: _vm.getClickInfo,
                ready: _vm.onBaiduMapReady,
                moving: _vm.syncCenterAndZoom,
                moveend: _vm.syncCenterAndZoom,
                zoomend: _vm.syncCenterAndZoom
              }
            },
            [
              _c("bm-view", { staticClass: "map" }),
              _vm._v(" "),
              _c("bm-point-collection", {
                attrs: {
                  points: _vm.placeList,
                  shape: "BMAP_POINT_SHAPE_CIRCLE",
                  color: "#09f",
                  size: "BMAP_POINT_SIZE_BIG"
                },
                on: { click: _vm.clickHandler }
              }),
              _vm._v(" "),
              _c(
                "bm-info-window",
                {
                  attrs: {
                    position: _vm.infoWindowPoint,
                    title: "",
                    show: _vm.show
                  },
                  on: { close: _vm.infoWindowClose, open: _vm.infoWindowOpen }
                },
                [
                  _c("div", [
                    _c("p", [
                      _c("img", {
                        staticClass: "img",
                        attrs: { src: _vm.infoWindowPoint.pimg, alt: "" }
                      })
                    ]),
                    _vm._v(" "),
                    _c("p", [
                      _vm._v("名称 : " + _vm._s(_vm.infoWindowPoint.pname))
                    ]),
                    _vm._v(" "),
                    _c("p", [
                      _vm._v(
                        "类型 : " +
                          _vm._s(
                            ["服务点", "学校", "医院"][_vm.infoWindowPoint.type]
                          )
                      )
                    ]),
                    _vm._v(" "),
                    _c("p", [
                      _vm._v("地址 : " + _vm._s(_vm.infoWindowPoint.addr))
                    ])
                  ])
                ]
              )
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticStyle: { display: "none" }, attrs: { id: "iCenter" } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }