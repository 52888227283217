var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-select",
    {
      ref: "select",
      class: { multiselect: _vm.multiple, "cant-clear": !_vm.clearable },
      attrs: {
        "popper-class": _vm.customId + " " + _vm.selectId,
        filterable: _vm.filter,
        "filter-method": _vm.filterMethod,
        clearable: _vm.clearable,
        "allow-create": _vm.allowCreate,
        "reserve-keyword": "",
        "collapse-tags":
          _vm.multiple &&
          _vm.nativeValue &&
          _vm.nativeValue.length > _vm.maxShowCount,
        multiple: _vm.multiple,
        disabled: _vm.disabled,
        size: "small",
        qgs_param: _vm.$vnode.data.model.expression
      },
      on: { "visible-change": _vm.visible, change: _vm.change },
      model: {
        value: _vm.nativeValue,
        callback: function($$v) {
          _vm.nativeValue = $$v
        },
        expression: "nativeValue"
      }
    },
    [
      _vm._t("default"),
      _vm._v(" "),
      _vm._l(_vm.displayOptions, function(option, index) {
        return _c("el-option", {
          key: option[_vm.valueName] + "--" + index,
          attrs: {
            label: option[_vm.keyName],
            value: option[_vm.valueName],
            disabled: option.disabled
          }
        })
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }