var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "uploader" },
    [
      _c(
        "el-upload",
        {
          ref: "upload",
          class: {
            "image-uploader": _vm.type === "image",
            "is-disabled": _vm.disabled
          },
          attrs: {
            action: _vm.PROXY_URL + _vm.action,
            headers: _vm.headers,
            "on-progress": _vm.onProgress,
            "on-error": _vm.onError,
            "on-success": _vm.onSuccess,
            "on-remove": _vm.onRemove,
            "before-remove": _vm.beforeRemove,
            limit: _vm.calLimit,
            "file-list": _vm.fileList,
            "before-upload": _vm.beforeUpload,
            "on-preview": _vm.onPreview,
            "show-file-list": _vm.type === "button",
            data: _vm.params,
            "auto-upload": _vm.autoUpload,
            "on-change": _vm.onChange
          }
        },
        [
          _vm.type === "button"
            ? _c(
                "app-button",
                {
                  attrs: {
                    disabled: _vm.disabled || _vm.nativeDisabled,
                    loading: _vm.loading,
                    type: _vm.buttonType
                  }
                },
                [_vm._v(_vm._s(_vm.text))]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.type === "image"
            ? [
                _c("img", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.isShowImg && _vm.image[_vm.fileName],
                      expression: "isShowImg && image[fileName]"
                    }
                  ],
                  staticClass: "image",
                  attrs: { src: _vm.image[_vm.filePathName] }
                }),
                _vm._v(" "),
                _c("i", { staticClass: "el-icon-plus image-uploader-icon" })
              ]
            : _vm._e(),
          _vm._v(" "),
          _vm.type === "input"
            ? [
                _c("app-input", {
                  staticStyle: { width: "200px" },
                  attrs: { readonly: true },
                  model: {
                    value: _vm.image[_vm.fileName],
                    callback: function($$v) {
                      _vm.$set(_vm.image, _vm.fileName, $$v)
                    },
                    expression: "image[fileName]"
                  }
                }),
                _vm._v(" "),
                _c(
                  "app-button",
                  {
                    attrs: {
                      disabled: _vm.disabled,
                      loading: _vm.loading,
                      type: _vm.buttonType
                    }
                  },
                  [_vm._v(_vm._s(_vm.text))]
                )
              ]
            : _vm._e(),
          _vm._v(" "),
          _vm.tip
            ? _c(
                "div",
                {
                  staticClass: "el-upload__tip",
                  attrs: { slot: "tip" },
                  slot: "tip"
                },
                [_vm._v(_vm._s(_vm.tip))]
              )
            : _vm._e()
        ],
        2
      ),
      _vm._v(" "),
      (_vm.disabled || _vm.nativeDisabled || _vm.loading) &&
      _vm.type === "button"
        ? _c("div", { staticClass: "uploader-mask" })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }