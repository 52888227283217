var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-form-item",
    {
      attrs: {
        label: _vm.label,
        prop: _vm.prop,
        "qgs-prop": _vm.prop,
        required: _vm.required,
        rules: _vm.rules,
        "label-width": _vm.labelWidth
      }
    },
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }