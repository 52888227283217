var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.backName
    ? _c(
        "div",
        {
          staticClass: "dashboard__back detail-page",
          class: { shadow: _vm.shadow }
        },
        [
          _vm.backName
            ? _c("a", { staticClass: "back-button", on: { click: _vm.back } }, [
                _c("i", { staticClass: "icon icon-back" }),
                _vm._v(_vm._s(_vm.backName))
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm._t("default")
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }