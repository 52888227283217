var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "autocomplete",
      class: _vm.id,
      staticStyle: { height: "30px" }
    },
    [
      _c(
        "el-popover",
        {
          ref: "popover-autocomplete",
          attrs: {
            trigger: "click",
            width: _vm.width,
            "popper-class":
              "autocomplete__select_" + _vm.id + " autocomplete__select"
          },
          model: {
            value: _vm.show,
            callback: function($$v) {
              _vm.show = $$v
            },
            expression: "show"
          }
        },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading"
                },
                {
                  name: "table-load",
                  rawName: "v-table-load",
                  value: _vm.tableLoadMore,
                  expression: "tableLoadMore"
                }
              ],
              attrs: {
                border: "",
                height: "240",
                width: "600",
                "row-class-name": _vm.getActiveClass,
                data: _vm.datas
              },
              on: { "row-click": _vm.rowClick }
            },
            _vm._l(_vm.tableHeader, function(col, index) {
              return _c("el-table-column", {
                key: index,
                attrs: { label: col.display, prop: col.prop }
              })
            }),
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      !_vm.disabled
        ? _c("el-input", {
            directives: [
              {
                name: "popover",
                rawName: "v-popover:popover-autocomplete",
                arg: "popover-autocomplete"
              }
            ],
            ref: "input",
            staticClass: "autocomplete-component",
            attrs: {
              placeholder: "请输入",
              size: "small",
              "on-icon-click": _vm.focusInput,
              "suffix-icon": "search"
            },
            on: { focus: _vm.focusInput, blur: _vm.blurInput },
            nativeOn: {
              keyup: [
                function($event) {
                  return _vm.getRemoteData($event)
                },
                function($event) {
                  if (!$event.type.indexOf("key") && $event.keyCode !== 13) {
                    return null
                  }
                  return _vm.keyupEnter(_vm.current)
                }
              ]
            },
            model: {
              value: _vm.nativeValue,
              callback: function($$v) {
                _vm.nativeValue = $$v
              },
              expression: "nativeValue"
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.disabled
        ? _c("el-input", {
            ref: "input",
            staticClass: "autocomplete-component",
            attrs: {
              placeholder: "请输入",
              size: "small",
              disabled: "",
              "suffix-icon": "search"
            },
            model: {
              value: _vm.nativeValue,
              callback: function($$v) {
                _vm.nativeValue = $$v
              },
              expression: "nativeValue"
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }