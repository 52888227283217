import { render, staticRenderFns } from "./app-input.vue?vue&type=template&id=1b1aecbd&"
import script from "./app-input.vue?vue&type=script&lang=js&"
export * from "./app-input.vue?vue&type=script&lang=js&"
import style0 from "./app-input.vue?vue&type=style&index=0&lang=less&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/zhouwenbo/Documents/work/lk/vue-project-modle/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('1b1aecbd')) {
      api.createRecord('1b1aecbd', component.options)
    } else {
      api.reload('1b1aecbd', component.options)
    }
    module.hot.accept("./app-input.vue?vue&type=template&id=1b1aecbd&", function () {
      api.rerender('1b1aecbd', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/resource/components/app-input.vue"
export default component.exports